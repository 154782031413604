


/* .carouselhover-enabled  {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.5s ease;
}

.carouselhover-disabled  {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
    transition: all 1s ease;
} */


.closeButtonPosition {
    position: absolute;
     top: 50px;
     right: 75px; 
     z-index: 10;
}
.projectImage {
    position:relative; 
    height: 300px;
    margin-bottom: 16px;
    max-width: 600px;
    min-width: 28%;
}
/* .carousel_icon_size {
    width: 100px !important; 
    height: 100px !important;
} */

/* .WidgetButtonSize {
    font-size:65px;
} */


/* 
.imageCarousel {
    width:100%;
    object-fit: contain;
    max-width: 1000px;
} */

/* .popupWidget {
    position: fixed; 
    top:45%;
    left:50%; 
    z-Index:2;
    transform: translate(-50%, -50%);
} */
.carousel-control-prev {
    /* left: -2vw; */
    /* filter: invert(50%) Sepia(20%) saturate(99999%) hue-rotate(360deg) brightness(100%) contrast(100%); */
    opacity: 1 !important;
    left: 4vw;
}
.carousel {
    padding-top: 95px;
}
.carousel-item{
    width:100vw;
}
.carousel-control-next {
    /* right: -2vw; */

    opacity: 1 !important;
    right: 4vw;
    /* filter: invert(50%) Sepia(20%) saturate(99999%) hue-rotate(360deg) brightness(100%) contrast(100%); */
} .carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '';
  font-weight: bold;
  font-size: 55px;
  color: red !important;
}

.carousel-control-prev-icon:after {
  content: '';
  font-size: 55px;
  font-weight: bold;
  color: red !important;
}

.projectImageSize {
    width: auto;
    height: 60%;
}
.carouselPadding {
    max-height: 70vh;
    width: 70%
}
.widgetText {
    margin-top:32px;
    bottom: 14px;
    text-align: justify;
     padding: 18;
     margin-left: auto;
     margin-right: auto;
     position: relative;
     left: 0;
     color: #5f5f5f;
     width: 70% ;
     
}
@media only screen and (max-width: 2100px) {
    .projectImage {
        position:relative; 
        height: 300px;
        margin-bottom: 16px;
        max-width: 600px;
    }
    .projectImageSize {
        width: auto;
        height: 60%;
    }
}

 @media only screen and (max-width: 1640px) {

    .projectImage {
        height: 200px;
        max-width: 500px;
    }
    .projectImage {
        position:relative; 
        height: 300px;
        margin-bottom: 16px;
        max-width: 160px;
    }
    .projectImageSize {
        width: 80%;
        height: 80%;
    }
}
@media only screen and (max-width: 1240px) {

    .projectImageSize {
        width: 100%;
        height: 100%;
    }
}


@media only screen and (max-width: 800px) {

    .projectImage {
        position:relative; 
        margin-bottom: 0;
        max-height: 130px;
        width:30%;
    }

    .carouselPadding {
        width: 70%;
        padding:0;
    }
    .closeButtonPosition {
        position: absolute;
         top: 10px;
         right: 10px; 
         z-index: 10;
    }

    .WidgetButtonSize {
        font-size:25px;
    }
  } 