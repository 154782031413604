.topbar {
  /*  */
  padding:0px 0px;
  margin: 40px 0 20px 0;
  /* margin-top:20px; */
}

.topbarSize{
  font-size: clamp(0.8em,1em,2em);
}
.topBarPadding {
  padding-bottom:0
}
@media only screen and (max-width: 840px) {
  .topbarSize{
  }

  .topBarPadding {
    padding-bottom:0
  }
}

@media only screen and (max-width: 600px) {
  .topbar {
    /*  */
    padding:0px 0px;
    margin: 40px 0 20px 0;
    /* margin-top:20px; */
  }
}

.button {
  font-weight: bold;
} 
.marginSubstractor {
margin-right: -8px;
margin-left: -8px;
}

.marginSubstractor>* {
padding: 8px;
}

.marginSubstractor.notop>* {
padding: 0 8px;
}

.topar {
padding: 0 8px;
font-size: 18px;
max-width: 1280px;
}

.transparent {
background: rgba(0, 0, 0, 0);
color: white;
}

.white {
color: #666;
}
.white:hover {
color: #5f5f5f;
}

.sidebarcontainer {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: calc(100%);
background: rgba(0, 0, 0, 0.4);
transition: all 1s;
}

.sidebarcontainer a {
display: block;
}

.sidebar {
  height: calc(100%);

  overflow: hidden;
background: white;
position: fixed;
top: 0;
right: 0;
color: #666;
max-width: 320px;
min-width: 240px;
}

.sidebar .Topbarlink {
color: #666;
font-size: clamp(.8em,1em,2em);
margin: 5px 10px
}

.sidebar .Topbarlink:hover {
color: #666;
font-size: clamp(.8em,1em,2em);
margin: 5px 10px
}

