
.Topbarlink {
  cursor: pointer;
  margin: 0 1.5vw;
}

.Topbarlink:hover {
  text-decoration: underline;
}

.TopbarlinkIcon {
  margin-right: 5px;
}

.innerText {
  @media (min-width: map-get($sizes, "md")) {
    padding: 0;
  }
}
@media only screen and (max-width: 1100px) {
  .Topbarlink {
    cursor: pointer;
    margin: 0 12px;
  }
}