body {
  margin: 0;
  font-family: 'AvenirBlack' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
}

.fontBlack {
  font-family: 'AvenirBlack' !important;
}
@font-face {
  font-family: 'AvenirBlack';
  src: local('AvenirBlack'), url(fonts/AvenirBlack.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


span.empty_fill {
  display:block;
  overflow:hidden;
  width:100%;
  height:100%;
}

.teamImage {
  opacity: 1;
  height: 18em;  
   max-height: 533;
    object-fit: contain;
     max-width: 180.72px;
}
@media only screen and (max-width: 840px) {
  .teamImage {
    opacity: 1;
    height: 24em;  
     max-height: 533;
      object-fit: contain;
       max-width: 244.72px;
  }
}
.carousel-inner {
width:"100vw";
}


/* [1] The container */
.img-hover-zoom {
  /* [1.1] Set it as per your need */
  overflow: hidden;
  /* [1.2] Hide the overflowing of child elements */
} 

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform 1.1s ease-in-out;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.1);
}

.marginPlaceholder-small {
  margin-bottom: 24px !important;
}

.marginPlaceholder {
  margin-bottom: 48px !important;
}

.marginPlaceholder-large {
  margin-bottom: 96px !important;
}


a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;

}

h1 {
  font-size: 2em;
  font-weight: bold;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: 0.83em;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
}

.h1Header {
  font-size: 2em;
  font-weight: bold;
}

.h2Header {
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: 0.83em;
}

.h3Header {
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
}

.h4Header {
  font-size: 16px;
  font-weight: bold;
}

.blackfakelink {
  color:#000000 ;
  border-bottom: solid 2px #adadad;
  text-decoration: none !important;
}

.blackfakelink:hover {
  background-color: #adadad10;
  color: #adadad;
}

.fakelink {
  color: #adadad;
  text-decoration: none ;}

.fakelink:hover {
  /* background-color: #b6b6b610; */
  color:#adadad;
  text-decoration: underline;
}

.nofakelink {
  color: #adadad;
  text-decoration: none !important;
  border-bottom: solid 0px #5f5f5f00;
}

.nofakelink:hover {
  /* background-color: #b6b6b610; */
  color:#adadad;
  border-bottom: solid 0px #5f5f5f00;
}


