
.mainComp {
    width: 85vw;
    max-width: 1920px;
  }
  .landingHeader {
    font-size:1em;

  }
  .videoHeight {
    height: 38vh !important;
  }
  .videoPadding {
    width: 35vw !important;
  }

  .headerFontSize {
font-size: clamp(0.8em,1.2em,3em) ;  }
  .subheaderFontSize {
    font-size: 1.5em;
  }
  @media only screen and (min-width: 2100px) {
    .headerFontSize {
      font-size: clamp(0.8em,2em,3em) ; 
    }
    .subheaderFontSize {
      font-size: 1.2em !important;
    }
    .mainComp {
      width: 82vw;
      max-width: 1920px;
    }
    .videoPadding {
      width: 40vw !important;
      padding:0 !important;
    }
  }

  @media only screen and (max-width: 1520px) { 
    .videoPadding {
      width: 55vw !important;
      padding:0 !important;
    }
  }

  @media only screen and (max-width: 900px) {
 
    .videoPadding {
      width: 65vw !important;
      padding:0 !important;
    }

    .mainComp {
      width: 85vw;
      max-width: 1280px;
   
    }
    .landingHeader {
      font-size:0.5em;
    }
  }

  @media only screen and (max-width: 644px) {
 .headerFontSize{
   font-size:1em;
 }
    .videoPadding {
      width: 80vw !important;
      padding:0 !important;
    }
  }
